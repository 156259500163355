import React from 'react';
import {Link} from 'react-router-dom';
import './Landing.css'; // The CSS for styling

const Landing: React.FC = () => {
    return (
        <>
            {/* Hero Section */}
            <h1>Berlin House</h1>
            <h2 className="hero-subtitle">
                A vertical village - a community 3rd space for frontier technologies mixed with arts & music
            </h2>
            <p>
                We need 300 signups to make this project a reality. Sign up now to become a founding member and
                get lifelong benefits.
            </p>

            {/* CTA Button */}
            <Link to="/checkout" className="cta-button">
                Join Us
            </Link>
            <br />
            <br />
            <br />
            <a href="/files/membership-terms.pdf" target="_blank">Membership Terms</a>
        </>
    );
};

export default Landing;
