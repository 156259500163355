import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Landing from './pages/Landing';
import Checkout from './pages/Checkout';
import Success from './pages/Success';
import HeaderAnimation from "./pages/HeaderAnimation";

// Import Stripe
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import config from "./config";

// Your Stripe public key
const stripePromise = loadStripe(config.stripePublicKey);

const App: React.FC = () => {
    return (
        <div className={"root"}>
            <HeaderAnimation/>
            <div className="page">
                {/* Main Content */}
                <div className="content">
                    <Router>
                        <Elements stripe={stripePromise}>
                            <Routes>
                                <Route path="/" element={<Landing/>} />
                                <Route path="/checkout" element={<Checkout/>} />
                                <Route path="/success" element={<Success/>} />
                            </Routes>
                        </Elements>
                    </Router>
                </div>
            </div>
        </div>
    );
};

export default App;
