interface Config {
    baseUrl: string;
    apiUrl: string;
    stripePublicKey: string;
    priceIdDigitalAmbassadorMonthly: string;
    priceIdDigitalAmbassadorYearly: string;
    priceIdStudentMonthly: string;
    priceIdStudentYearly: string;
    priceIdFoundingMemberMonthly: string;
    priceIdFoundingMemberYearly: string;
}

const config: Config = {
    baseUrl: "https://berlinhouse.com",
    apiUrl: "https://api.berlinhouse.com",
    stripePublicKey: "pk_test_51PzgVFCQ7Ev3Nqcs5WAk65HNXD8nFptYn5VJrCb56zSI9vCAFtHZuH6aty05kZZp0f7LJSY0EmGewwqjyLyBQiu100ocK7c5cr",
    priceIdDigitalAmbassadorMonthly: 'price_1Q0f1MCQ7Ev3NqcsUUrvdCPO',
    priceIdDigitalAmbassadorYearly: 'price_1Q0f1WCQ7Ev3NqcsDMWTFXDT',
    priceIdStudentMonthly: 'price_1Q0f1DCQ7Ev3NqcsViNuu9kN',
    priceIdStudentYearly: 'price_1Q0f4xCQ7Ev3NqcsnbkIdVNm',
    priceIdFoundingMemberMonthly: 'price_1PzgefCQ7Ev3NqcsthgOc56Z',
    priceIdFoundingMemberYearly: 'price_1Q0f5FCQ7Ev3NqcsfjONWE18',
};

export default config;