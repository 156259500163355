import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import './Success.css';
import config  from "../config";

const Success: React.FC = () => {
  const url = config.baseUrl;

  return (
    <div className="success-page">
      <h1>Thank You!</h1>
      <p>We'll get in contact shortly with your personalized membership information.</p>
      <p>Spread the word and let your friends know about Berlin House:</p>
      <div className="social-share">
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`}
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon"
        >
          <FaFacebook /> Share on Facebook
        </a>

        <a
          href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=Join%20Berlin%20House%20-%20A%20Community%20Space%20for%20Frontier%20Technologies!`}
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon"
        >
          <FaTwitter /> Share on X
        </a>

        <a
          href={`https://www.instagram.com/?url=${encodeURIComponent(url)}`}
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon"
        >
          <FaInstagram /> Share on Instagram
        </a>
      </div>

      <Link to="/" className="back-home">
        Back to Home
      </Link>
    </div>
  );
};

export default Success;
