import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import './Checkout.css'; // Add any styling as needed
import config from '../config';

const Checkout: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [oneTimeCode, setOneTimeCode] = useState('');
  const [tier, setTier] = useState('digitalAmbassador'); // Default to the lowest tier
  const [billingCycle, setBillingCycle] = useState('monthly'); // Default to monthly
  const [error, setError] = useState('');

  const getPriceId = () => {
    if (tier === 'digitalAmbassador') {
      return billingCycle === 'monthly' ? config.priceIdDigitalAmbassadorMonthly : config.priceIdDigitalAmbassadorYearly;
    } else if (tier === 'student') {
      return billingCycle === 'monthly' ? config.priceIdStudentMonthly : config.priceIdStudentYearly;
    } else if (tier === 'foundingMember') {
      return billingCycle === 'monthly' ? config.priceIdFoundingMemberMonthly : config.priceIdFoundingMemberYearly;
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      return;
    }

    try {
      const priceId = getPriceId();

      // Request to your server to create a payment intent
      const response = await fetch(`${config.apiUrl}/process_payment/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          oneTimeCode,
          priceId,
        }),
      });

      const { clientSecret } = await response.json();

      // Confirm card payment
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: `${firstName} ${lastName}`,
            email: email,
          },
        },
      });

      if (result.error) {
        setError(result.error.message || 'Payment failed.');
      } else {
        if (result.paymentIntent?.status === 'succeeded') {
          // Payment successful, navigate to the success page
          window.location.href = '/success';
        }
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="checkout-page">
      <h1>Checkout</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="firstName">First Name</label>
          <input
            type="text"
            id="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="lastName">Last Name</label>
          <input
            type="text"
            id="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="oneTimeCode">One-Time Code</label>
          <input
            type="text"
            id="oneTimeCode"
            value={oneTimeCode}
            onChange={(e) => setOneTimeCode(e.target.value)}
            required
          />
        </div>

        <div>
          <label>Pricing Tier</label>
          <div className="switch-container">
            <input
              type="radio"
              id="digitalAmbassador"
              name="tier"
              value="digitalAmbassador"
              checked={tier === 'digitalAmbassador'}
              onChange={(e) => setTier(e.target.value)}
            />
            <label htmlFor="digitalAmbassador">Digital Ambassador</label>
            <input
              type="radio"
              id="student"
              name="tier"
              value="student"
              checked={tier === 'student'}
              onChange={(e) => setTier(e.target.value)}
            />
            <label htmlFor="student">Junior Member</label>
            <input
              type="radio"
              id="foundingMember"
              name="tier"
              value="foundingMember"
              checked={tier === 'foundingMember'}
              onChange={(e) => setTier(e.target.value)}
            />
            <label htmlFor="foundingMember">Founding Member</label>
          </div>
        </div>

        <div>
          <label>Billing Cycle</label>
          <div className="switch-container">
            <input
              type="radio"
              id="monthly"
              name="billingCycle"
              value="monthly"
              checked={billingCycle === 'monthly'}
              onChange={(e) => setBillingCycle(e.target.value)}
            />
            <label htmlFor="monthly">Monthly</label>
            <input
              type="radio"
              id="yearly"
              name="billingCycle"
              value="yearly"
              checked={billingCycle === 'yearly'}
              onChange={(e) => setBillingCycle(e.target.value)}
            />
            <label htmlFor="yearly">Yearly</label>
          </div>
        </div>

        <div>
          <label>Card Details</label>
          <CardElement />
        </div>

        {error && <div className="error">{error}</div>}

        <button type="submit" disabled={!stripe || loading}>
          {loading ? 'Processing...' : `Subscribe ${tier === 'digitalAmbassador' ? (billingCycle === 'monthly' ? '55' : '44') : tier === 'student' ? (billingCycle === 'monthly' ? '120' : '100') : (billingCycle === 'monthly' ? '180' : '150')} EUR`}
        </button>
      </form>
    </div>
  );
};

export default Checkout;